import React from 'react';
import PropTypes from 'prop-types';

import Competitor from './Competitor';

import './Competitors.scss';

const Competitors = ({ pages, title }) => (
  <div className='competitors-wrapper'>
    <h1 className='stocks-title'>{title}</h1>
    <div className='competitors'>
      {pages.map(({ headLine, path }) => (
        <Competitor
          key={path} path={path}
          headLine={headLine}
        />
      ))}
    </div>
  </div>
);

export default Competitors;

Competitors.propTypes = {
  pages: PropTypes.array,
  title: PropTypes.string,
};
