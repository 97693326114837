import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './Competitor.scss';


const Competitor = ({ path, headLine }) => (
  <Link className='competitor' to={path}>
    {headLine}
  </Link>
);

export default Competitor;

Competitor.propTypes = {
  path: PropTypes.string,
  headLine: PropTypes.string,
};
