/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from '../components/seo';

import Competitors from '../components/Common/Competitors/Competitors';
import Slider from '../components/Slider/Slider';

import { PAGES } from '../constants/Languages';
import { PAGES_LINKS } from '../constants/pagesLinks';
import layoutContext from '../components/layoutContext';


const Companies = ({ data }) => {
  useContext(layoutContext).setCult('ru');
  const { competitors } = data;
  const { edges } = competitors;

  const pages = edges.reduce((
    pages,
    { node: {
      frontmatter: {
        headLine,
        path,
        lang,
        category } } }) => {
    if (lang === 'ru' && category === 'competitors' && headLine) {
      pages.push({ headLine, path });
    }
    return pages;
  }, []);


  return (
    <div>
      <Seo
        title='Все микрокредитные организации Украины. Микрозаймы онлайн, МФО - globalcredit.ua'
        path={PAGES_LINKS['competitors']}
      />
      <Slider />
      <Competitors pages={pages} title={PAGES.company_allukraine['ru']} />
    </div>
  );
};

export default Companies;


Companies.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired
            })
          })
        }).isRequired
      )
    })
  })
};


export const pageQuery = graphql`
    {
      competitors: allMarkdownRemark(
        sort: {fields: [frontmatter___date], order: DESC},
        filter: {fileAbsolutePath: {regex: "/content/"}}
      ) {
        totalCount
        edges {
          node {
            frontmatter {
              headLine
              path
              lang
              category
            }
          }
        }
      }
    }   
`;
